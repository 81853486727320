<template>
  <div>
    <v-alert primary class="rounded-0">
      <v-container>
        <v-layout row class="tBar">
          <v-flex>
            <v-btn
              depressed
              color="secondary"
              v-on:click.stop
              class="mr-6"
              :href="
                '/darkrecon/conversations/' +
                this.$route.params.conversationId +
                '/messages/' +
                this.$route.params.messageId
              "
              >View Message</v-btn
            >
            <v-btn
              depressed
              color="error"
              v-if="loadedObf == true"
              @click="download_image"
              >Download</v-btn
            >
            <v-btn depressed v-if="loadedObf == false" :disabled="true"
              >Download</v-btn
            >
          </v-flex>
        </v-layout>
      </v-container>
    </v-alert>
    <div
      class="d-flex flex-column justify-space-between align-center pb-4 imageArea"
      v-if="noResultsFound == false"
    >
      <v-skeleton-loader
        type="image"
        v-if="loadedObf == false"
      ></v-skeleton-loader>
      <div v-for="(item, index) in screenshotObf" :key="index">
        <img
          style="
            max-width: 1000px;
            height: auto;
            margin-top: 1em;
            margin-bottom: 1em;
          "
          :src="item.url"
          v-if="loadedObf == true"
        />
      </div>
    </div>
    <div class="mt-10" v-if="noResultsFound == true">
      <p class="text-center body-1">No screenshot found</p>
    </div>
    <v-snackbar v-model="showClipboard" class="snackbarText">
      Copied!
      <v-btn :ripple="false" color="#333333" text @click="showClipboard = false"
        >Close</v-btn
      >
    </v-snackbar>
  </div>
</template>
<script>
import store from "../store/index";
export default {
  name: "DarkReconScreenshots",
  data: () => ({
    loadedObf: false,
    showClipboard: false,
    noResultsFound: false,
  }),
  beforeMount() {
    store.dispatch("darkRecon/addScreenshots", [
      this.$route.params.conversationId,
      this.$route.params.messageId,
    ]);
  },
  mounted() {
    console.log(
      this.$route.params.conversationId,
      this.$route.params.messageId
    );
  },
  methods: {
    download_image() {
      const fileLink = document.createElement("a");
      fileLink.href = this.screenshotObf[0].url;
      fileLink.setAttribute("download", "screenshot.png");
      document.body.appendChild(fileLink);
      fileLink.click();
      document.body.removeChild(fileLink);
    },
  },
  computed: {
    screenshotObf() {
      return store.state.darkRecon.screenshotObf;
    },
  },
  watch: {
    screenshotObf: function (val) {
      console.debug(val);
      if (val !== null && val !== undefined) {
        if (val.error !== "No screenshot found.") {
          this.loadedObf = true;
        }
        if (val.error == "No screenshot found.") {
          this.noResultsFound = true;
        }
      }
    },
  },
};
</script>
<style scoped>
.tBar {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}
.imageArea >>> .v-skeleton-loader__image {
  height: 1000px;
  width: 1000px;
}
</style>
