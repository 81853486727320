import { render, staticRenderFns } from "./DarkReconScreenshots.vue?vue&type=template&id=23119650&scoped=true&"
import script from "./DarkReconScreenshots.vue?vue&type=script&lang=js&"
export * from "./DarkReconScreenshots.vue?vue&type=script&lang=js&"
import style0 from "./DarkReconScreenshots.vue?vue&type=style&index=0&id=23119650&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23119650",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VAlert,VBtn,VContainer,VFlex,VLayout,VSkeletonLoader,VSnackbar})
